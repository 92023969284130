<template>
  <button
    :class="component.theme"
    class="group flex font-bold empty:hidden items-center gap-4"
    type="button"
  >
    <IconsChevron
      :class="component.icon"
      direction="left"
      size="default"
    />

    {{ text }}
  </button>
</template>

<script setup>
defineOptions({
  name: 'AtomsButtonBack'
})

const props = defineProps({
  text: {
    type: String,
    default: 'BACK'
  },

  theme: {
    type: String,
    default: 'default',
    validator: value => ['default', 'dark', 'darkGray', 'white'].includes(value)
  }
})

const component = {
  theme: (() => {
    const color = getKey(props.theme, {
      default: 'text-gray-75 hover:text-gray-40',
      darkGray: 'text-gray-80 hover:text-gray-40',
      white: 'text-white',
      dark: 'text-dark font-bold'
    })

    const text = getKey(props.theme, {
      default: 'text-sm/[1.3125rem]',
      'darkGray, dark': 'text-base/[1.625rem]'
    })

    return removeClassExtras(`${color} ${text}`)
  })(),

  icon: getKey(props.theme, {
    default: 'fill-gray-75 group-hover:fill-gray-75',
    darkGray: 'fill-gray-80 group-hover:fill-gray-40',
    white: 'fill-white group-hover:fill-white',
    dark: 'fill-dark'
  })
}
</script>
